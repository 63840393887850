<template>
  <div class="login-form login-signin">
    <form
      class="form"
      novalidate="novalidate"
      id="kt_login_signin_form"
    >
      <div class="pb-13 pt-lg-0 pt-5">
        <div class="symbol-label" style="opacity:100%; ">
          <inline-svg
            alt="Logo"
            src="media/logo/logo.svg"
            style="
            max-width:85px;
            max-height:130px;"
          />
        </div>
        <h3
          class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
        >
          Welcome to Cloakone
        </h3>
        <span class="text-muted font-weight-bold font-size-h4"
          >New Here?
          <router-link
            class="text-primary font-weight-bolder"
            to="/signup"
            >Create an Account</router-link
          ></span
        >
      </div>
      <div class="form-group">
        <label class="font-size-h6 font-weight-bolder text-dark"
          >Email</label
        >
        <div
          id="example-input-group-1"
          label=""
          label-for="example-input-1"
        >
          <input
            class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
            type="text"
            name="email"
            ref="email"
            v-model="form.email"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="d-flex justify-content-between mt-n5">
          <label class="font-size-h6 font-weight-bolder text-dark pt-5"
            >Password</label
          >
          <a
            class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
            id="kt_login_forgot"
            @click="showForm('forgot')"
            >Forgot Password ?</a
          >
        </div>
        <div
          id="example-input-group-2"
          label=""
          label-for="example-input-2"
        >
          <input
            class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
            type="password"
            name="password"
            ref="password"
            v-model="form.password"
            autocomplete="off"
          />
        </div>
      </div>
      <AuthErrors />
      <div class="pb-lg-0 pb-5">
        <button
          ref="kt_login_signin_submit"
          class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
        >
          Sign In
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import AuthErrors from '@/view/components/Auth/AuthErrors';
import { mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "AuthSignIn",
  components: {
    AuthErrors
  },
  props: {
    showForm: Function
  },
  data() {
    return {
      form: {
        email: "",
        password: ""
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Username is required"
            }
          }
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.valid", () => {
      var email = this.form.email;
      var password = this.form.password;



      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
          .dispatch(LOGIN, { email, password })
          // go to which page after successfully login
          .then(() => this.$router.push({ name: "dashboard" }))
          .catch(() => {});

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 3000);
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: true
      });
    });
  }
};
</script>

<style scoped>
.text-primary {
  cursor: pointer;
}
</style>
