<template>
  <div class="login-form login-forgot">
    <!--begin::Form-->
    <form
      class="form"
      novalidate="novalidate"
      id="kt_login_forgot_form"
      ref="kt_login_forgot_form"
    >
      <div class="pb-13 pt-lg-0 pt-5">
        <h3
          class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
        >
          Forgotten Password ?
        </h3>
        <p class="text-muted font-weight-bold font-size-h4">
          Enter your email to reset your password
        </p>
      </div>
      <div class="form-group">
        <input
          class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
          type="email"
          placeholder="Email"
          name="email"
          autocomplete="off"
        />
      </div>
      <div class="form-group d-flex flex-wrap pb-lg-0">
        <button
          type="button"
          id="kt_login_forgot_submit"
          class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
        >
          Submit
        </button>
        <button
          type="button"
          id="kt_login_forgot_cancel"
          class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
          @click="showForm('signin')"
        >
          Cancel
        </button>
      </div>
    </form>
    <AuthErrors />
  </div>
</template>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import AuthErrors from '@/view/components/Auth/AuthErrors';

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";

export default {
  name: "AuthForgot",
  components: {
    AuthErrors
  },
  props: {
    showForm: Function
  },
  data() {
    return {
      form: {
        email: ""
      }
    };
  },
  mounted() {
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Email is required"
            },
            emailAddress: {
              message: "The value is not a valid email address"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });
  }
};
</script>
