<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-forgot-on': this.state == 'forgot'
      }"
      id="kt_login"
    >
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <AuthSignIn v-if="this.state == 'signin'" :showForm="showForm" />
          <AuthForgot v-if="this.state == 'forgot'" :showForm="showForm" />
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<script>
import AuthSignIn from '@/view/components/Auth/AuthSignIn';
import AuthForgot from '@/view/components/Auth/AuthForgot';

import KTUtil from "@/assets/js/components/util";
import { SET_ERROR } from "@/core/services/store/auth.module";

export default {
  name: "Login",
  components: {
    AuthSignIn,
    AuthForgot
  },
  data() {
    return {
      state: "signin"
    };
  },
  methods: {
    showForm(form) {
      this.$store.commit(SET_ERROR, []);

      this.state = form;

      this.$nextTick(() => {
        const form_name = "kt_login_" + form + "_form";
        KTUtil.animateClass(
          KTUtil.getById(form_name),
          "animate__animated animate__backInUp"
        );
      })
    }
  }
};
</script>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<style scoped>
.text-primary {
  cursor: pointer;
}

input[name="referralEmail"].is-valid.without-value {
  background-image: none;
}
</style>
